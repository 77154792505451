<template>
  <div id="app">
    <Menu :addClass="menuClass"/>
    <Map :addClass="menuClass"/>
  </div>
</template>

<style lang="scss">
@import '~include-media/dist/_include-media.scss';
@import './scss/colors.scss';
$navbar-height: 75px;
$navbar-height-mobile: 40px;

*, body, html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  display: flex;
  @include media("<tablet") {
    flex-direction: column-reverse;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  position: relative;
  left:0;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #091418;
  color: #DEDDD4;

  &.slim {
    width: 360px;
    @include media("<tablet") {
      height: 50vh;
      width: 100vw;
    }
  }

  &.full {
    width: 100vw;
    #nav {
      padding-top: 50px;
    }
    @include media("<tablet") {
      height: 100vh;
    }
  }

  &.half {
    width: 50vw;
    @include media("<tablet") {
      height: 50vh;
      width: 100vw;
    }
  }

  .navbar {
    height: $navbar-height;
    @include media("<tablet") {
      height: $navbar-height-mobile;
      position: fixed;
      top: 0px;
      width: 100%;
    }

    .navbar-contents {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      padding-top: 0px;

      @include media("<tablet") {
        padding-top: 0px;
      }

      .navbar-center, .navbar-left, .navbar-right {
        display: flex;
        justify-content: center;
        min-width: 45px;

        height: $navbar-height;
        align-items: center;

        @include media("<tablet") {
          height: $navbar-height-mobile;
          align-items: center;
        }
      }
      .navbar-center {
        flex-grow: 10;
      }

      .navbar-left {
        flex-shrink: 1;
        justify-content: flex-start;
        padding-left: 10px;
      }

      .navbar-right {
        flex-shrink: 1;
        justify-content: flex-end;
        padding-right: 10px;
      }

      &.home-page {
        @include media(">tablet") {
          .navbar-center, .navbar-left, .navbar-right {
            align-items: flex-start;
          }
          padding-top: 10px;
        }
      }

      .map-button {
        //position: absolute;
        background: transparent;
        outline: none;
        border: none;
        color: var(--color-red-ribbon);
        cursor: pointer;
        text-align: left;
        font-size: 21px;
      }

      .top-bar {
        font-size: 0.98em;
        min-height: 40px;
        background-color:#2c3e50;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        padding: 2px 4px 2px 4px;
        width: 100%;
      }

      .logo {
        img {
          height: $navbar-height - 7px;
          @include media('<tablet') {
            height: $navbar-height-mobile - 12px;
          }
          width: auto;
        }
        .logo-bottom {
          position: relative;
          left: 8px;
          top: -5px;
          color: #8a9ba9;
          font-weight: bold;
          display: none;
        }
      }

      .back-button {
        padding-left: 3px;
        font-size:26px;
        white-space: nowrap;
        background: transparent;
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
        text-align: left;
      }
    }
  }

  progress.top-progress::-webkit-progress-bar {
    background-color: #ededed;
    border-radius: 0px;
  }

  progress.top-progress::-webkit-progress-value {
    border-radius: 0px;
    background-color:var(--color-red-ribbon);
  }

  .top-progress {
    height: 3px;
    border-radius: 0px;
    width:100%;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .upload-area {
    padding-bottom: 20px;
    padding-top: 15px;
    @include media('<tablet') {
      padding-bottom: 2px;
      padding-top: 2px;
    }
  }

  input[type="file" i] {
    display:none
  }

  .custom-file-upload {
    /*border: 1px solid #ccc;*/
    color: var(--color-blue);
    border-radius: 5px;
    background-color: #ffffff;
    display: inline-block;
    padding: 10px 12px;
    cursor: pointer;
    font-weight: bold;
  }

  z-index: 10;
  text-align: center;
}

.map {
  position: relative;
  width: auto;
  flex:1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .icon {
    border-radius: 50%;
  }
  @include media("<tablet") {
    margin-top: $navbar-height-mobile;
  }
}

#nav {
  padding: 3px;
  color: #01bcdd;

  a {
    font-weight: bold;
    color: #7D7C7C;
    text-decoration: none;

    &.router-link-exact-active {
      color:#F20443;
    }
  }
}

h5 {
  margin: 0px;
}
</style>

<script>
import Menu from './components/Menu.vue'
import Map from './components/Map.vue'

export default {
  components: {
    Menu,
    Map
  },
  computed: {
    menuClass () {
      let cls = 'half'
      switch (this.$route.path) {
        case '/':
          if (this.$store.getters.locations.length > 0) {
            cls = 'slim'
            break
          }
          cls = 'full'
          break
        case '/about':
          cls = 'full'
          break
        default:
          cls = 'half'
          break
      }

      if (!this.$store.getters.showUIMap) {
        cls = 'full'
      }

      return cls
    }
  }
}
</script>
