<template>
  <div>
    <div
      v-if="!initialized"
    >
      <br><br>
      PicNav is a browser application to show your photos GPS location on map.<br><br>

      PicNav works locally. You don't have to upload your pictures to anywhere.<br><br>

      All magic happening right in your web browser.
    </div>
    <div
      v-else
      class="location-list"
    >
      <div class="tabs">
        <div
          :class="{active: tab === 0}"
          @click="tab = 0"
        >
          Locations
        </div>
        <div
          :class="{active: tab === 1}"
          @click="tab = 1"
        >
          Pictures
        </div>
      </div>
      <div
        v-if="locations.length > 0 && tab === 0"
        class="tab-content"
      >
        <router-link
          v-for="location in locations"
          tag="div"
          class="location-list-item"
          :key="'loc-' + location.id"
          :to="'/location/' + location.id"
          :name="'location' + location.id"
        >
          <div>
            <img
              v-if="location.icon"
              :src="location.icon.options.iconUrl"
              width="33px"
              height="33px"
              style="border-radius:50%"
            />
          </div>
          <div>
            {{ $store.getters.locationNameById(location.id) }}
          </div>
        </router-link>
      </div>
      <div
        v-if="pictures.length > 0 && tab === 1"
        class="tab-content"
      >
      <router-link
        v-for="picture of pictures"
        tag="div"
        class="picture-list-item"
        :key="'pic-' + picture.id"
        :to="'/picture/' + picture.id"
        :name="'picture' + picture.id"
      >
        <div>
          <img
            v-if="picture.thumbnail"
            :src="picture.thumbnail"
            width="33px"
            height="33px"
            style="border-radius:50%"
          />
        </div>
        <div>
          {{ picture.file.name }}
        </div>
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      tab: 0
    }
  },
  computed: {
    ...mapGetters(['locations', 'pictures']),
    initialized () {
      return this.$store.getters.pictures.length > 0
    }
  }
}
</script>

<style lang="scss">
  @use "sass:color";
  @import '../scss/colors.scss';

  .location-list {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
  }

  .location-list-item, .picture-list-item {
    width: 100%;
    font-size: 0.9em;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
    text-align: left;
    cursor: pointer;
    display: flex;
    min-height: 45px;
    & > div {
      display: flex;
      align-items: center;
      min-width: 40px;
    }
    &:hover {
      background-color: rgba(0,0,0,0.1);
      background-color: #0f1f25;
    }
  }

  .tab-content {
    margin-bottom: 5px;
    color: white;
    border-top: 1px solid var(--color-red-ribbon);
    padding-top: 4px;
    font-size: 14px;
    width: 100%;
  }

  .tabs {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: row;
    > div {
      cursor: pointer;
      padding: 4px 10px 4px 10px;
      background-color: color.adjust($--color-red-ribbon, $alpha: -0.5);
      &.active {
        background-color: var(--color-red-ribbon);
      }
    }
  }
</style>
