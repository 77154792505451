<template>
  <div class="map">
    <l-map
      style="height: 100%; width: 100%"
      ref="map"
      :center="mapCenter"
      :zoom="$store.state.mapZoom"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <template
        v-for="location of locations"
      >
        <l-marker
          v-if="location.icon"
          :key="location.id"
          :lat-lng="location.pictures[0].latLng"
          :icon="location.icon"
          @click="markerClicked(location)"
        >

        </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data () {
    return {
      url: 'https://mapserv.vatskies.com/mapnik/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }
  },
  computed: {
    ...mapGetters(['pictures', 'locations', 'mapCenter'])
  },
  methods: {
    markerClicked (location) {
      this.$router.push({ path: '/location/' + location.id })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
            console.error(err)
          }
        })
    }
  },
  props: {
    addClass: String,
    default: function () {
      return ''
    }
  },
  watch: {
    addClass (newC, oldC) {
      if (newC !== oldC) {
        console.log('Invalidate size.')
        this.$refs.map.mapObject.invalidateSize()
      }
    }
  }
}
</script>
