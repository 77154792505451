<template>
  <div class="menu" :class="addClass">
    <!-- Navbar -->
    <div class="navbar">
      <div
        class="navbar-contents"
        :class="{ 'home-page': $route.path === '/' }"
      >
        <div class="navbar-left">
          <button
            v-if="$route.path !== '/'"
            @click="$router.push('/')"
            class="back-button"
          >
            <font-awesome-icon icon="chevron-left"/>
          </button>
        </div>
        <div class="navbar-center">
          <div
            v-if="$route.path !== '/'"
            class="top-bar"
          >
            {{ menuTitle !== '' ? menuTitle : $route.name }}
          </div>
          <div
            v-else
            class="logo"
          >
            <div class="logo-top"><img src="../../media/logo-wide.png" /></div>
            <div class="logo-bottom">JPG EXIF Extractor</div>
          </div>
        </div>
        <div class="navbar-right">
          <button
            @click="$store.dispatch('setUIMap', !$store.getters.showUIMap)"
            class="map-button"
          >
            <font-awesome-icon :icon="['far', 'map']" />
          </button>
        </div>
      </div>
    </div>
    <!-- /Navbar -->
    <div
      v-if='showTop'
    >
      <div
        class="upload-area"
      >
        <progress
          v-if="progress.numberOfItems > 0 && progress.progress !== progress.numberOfItems"
          :value="progress.progress"
          :max="progress.numberOfItems"
          style="width:300px; display:block; margin-top:10px; margin-left: auto; margin-right: auto;"
        />
        <label
          v-else-if="progress.numberOfItems > 0"
          for="file-upload"
          class="custom-file-upload"
        >
          <font-awesome-icon icon="upload" />
          Add More Pictures
        </label>
        <label
          v-else
          for="file-upload"
          class="custom-file-upload"
        >
          <font-awesome-icon icon="upload" />
          Select Pictures
        </label>
        <input
          @change="loadPictures"
          id='file-upload'
          type='file'
          multiple
          style="width:300px"
        />
      </div>
    </div>
    <router-view
      @setMenuTitle="menuTitle = $event"
      @resetMenuTitle="menuTitle = ''"
    />
  </div>
</template>

<script>
import vuex from 'vuex'
export default {
  data () {
    return {
      menuTitle: ''
    }
  },
  computed: {
    ...vuex.mapGetters(['progress']),
    showTop () {
      return this.$route.path === '/' || this.$route.path === 'about'
    }
  },
  props: {
    addClass: String
  },
  methods: {
    loadPictures (event) {
      this.$store.dispatch('loadPictures', event)
    }
  }
}
</script>
