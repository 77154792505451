import Vue from 'vue'
import Vuex from 'vuex'
import EXIF from 'exif-js'
import L from 'leaflet'
import base64ArrayBuffer from 'base64-arraybuffer'

Vue.use(Vuex)


async function base64ToArrayBuffer (base64, contentType) {
  contentType = contentType || base64.match(/^data:([^;]+);base64,/mi)[1] || '' // e.g. 'data:image/jpeg;base64,...' => 'image/jpeg'
  base64 = base64.replace(/^data:([^;]+);base64,/gmi, '')
  const binary = atob(base64)
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  return buffer
}

async function arrayBufferToBase64 (input, contentType) {
  return 'data:' + contentType + ';Base64,' + base64ArrayBuffer.encode(input)
}

function exifToLatLng (exif) {
  if (!exif.GPSLatitude || !exif.GPSLongitude) {
    return false
  }

  function toDecimal (numberSet) {
    const degrees = numberSet[0]
    const minutes = numberSet[1]
    const seconds = numberSet[2]
    return degrees + minutes / 60 + seconds / 3600
  }
  const lat = toDecimal(exif.GPSLatitude) * (exif.GPSLatitudeRef === 'N' ? 1 : -1)
  const lon = toDecimal(exif.GPSLongitude) * (exif.GPSLatitudeRef === 'E' ? -1 : 1)

  return L.latLng(lat, lon, exif.GPSAltitude)
}

// https://stackoverflow.com/questions/20958078/resize-a-base-64-image-in-javascript-without-using-canvas
function resizedataURL (datas, wantedWidth, wantedHeight) {
  return new Promise((resolve, reject) => {
    try {
      // We create an image to receive the Data URI
      var img = document.createElement('img')

      // When the event "onload" is triggered we can resize the image.
      img.onload = function () {
        // We create a canvas and get its context.
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')

        // We set the dimensions at the wanted size.
        canvas.width = wantedWidth
        canvas.height = wantedHeight

        // We resize the image with the canvas method drawImage();
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

        var dataURI = canvas.toDataURL()

        // This is the return of the Promise
        resolve(dataURI)
      }

      // We put the Data URI in the image's src attribute
      img.src = datas
    } catch (err) {
      reject(err)
    }
  })
}

async function getFile (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const contentType = file.type
    reader.onload = (e) => {
      const exif = EXIF.readFromBinaryFile(e.target.result)
      const latLng = exifToLatLng(exif)

      if (exif.thumbnail) {
        const thumbReader = new FileReader()
        thumbReader.onload = (t) => {
          resolve({
            key: 0,
            file: file,
            contentType: contentType,
            exif: exif,
            latLng: latLng,
            icon: L.icon({
              iconUrl: t.target.result,
              iconSize: [64, 64],
              iconAnchor: [32, 32],
              className: 'icon'
            }),
            thumbnail: t.target.result
          })
        }

        thumbReader.readAsDataURL(exif.thumbnail.blob)
      } else {
        resolve({
          key: 0,
          file: file,
          contentType: contentType,
          exif: exif,
          latLng: latLng,
          icon: null,
          thumbnail: null
        })
      }

      /*
      arrayBufferToBase64(e.target.result, contentType)
        .then(result => {
          resizedataURL(result, 256, 256)
            .then(icon => {

            })
        })
        */
    }

    // reader.readAsDataURL(file)
    reader.readAsArrayBuffer(file)
  })
}

export default new Vuex.Store({
  state: {
    pictures: {},
    pictureIds: [],
    pictureIdCounter: 0,
    locations: {},
    locationIds: [],
    locationIdCounter: 0,
    progress: 0,
    numberOfItems: 0,
    mapCenter: L.latLng(40, 40),
    mapZoom: 6,
    showUIMap: true
  },
  getters: {
    showUIMap: state => { return state.showUIMap },
    mapCenter: state => { return state.mapCenter },
    pictures: state => {
      return state.pictureIds.map(id => { return state.pictures[id] })
    },
    pictureById: state => id => {
      return { ...state.pictures[id] }
    },
    locations: (state, getters) => {
      return state.locationIds.map(id => {
        const location = {
          ...state.locations[id]
        }

        location.pictures = location.pictures.map(picId => {
          return getters.pictureById(picId)
        })

        return location
      })
    },
    locationById: (state, getters) => id => {
      if (!state.locationIds.includes(parseInt(id))) {
        console.log([
          'loc not found',
          parseInt(id),
          state.locationIds,
          state.locationIds.includes(id),
          state.locations[id]
        ])
        return
      }

      const location = {
        ...state.locations[parseInt(id)]
      }

      if (location.pictures.length > 0) {
        location.pictures = location.pictures.map(picId => {
          return getters.pictureById(picId)
        })
      }

      return location
    },
    locationNameById: (state) => id => {
      if (!state.locationIds.includes(parseInt(id))) {
        return 'Unknown Location'
      }
      const location = {
        ...state.locations[parseInt(id)]
      }

      if (location.address) {
        const address = location.address.address
        const returnStr = []

        if (address.amenity) {
          returnStr.push(address.amenity)
        }

        if (address.hamlet) {
          returnStr.push(address.hamlet)
        }

        if (address.road) {
          returnStr.push(address.road)
        }

        if (address.suburb) {
          returnStr.push(address.suburb)
        }

        if (address.village) {
          returnStr.push(address.village)
        } else if (address.town) {
          returnStr.push(address.town)
        }

        if (address.province) {
          returnStr.push(address.province)
        } else if (address.region) {
          returnStr.push(address.region)
        }

        if (address.country) {
          returnStr.push(address.country)
        }

        if (returnStr.length > 0) {
          return returnStr.join(', ')
        }
      }

      return `Location: #${location.id}`
    },
    progress: (state) => ({
      progress: state.progress,
      numberOfItems: state.numberOfItems
    })
  },
  mutations: {
    SET_MAP_CENTER (state, payload) {
      state.mapCenter = payload
    },
    SET_MAP_ZOOM (state, payload) {
      state.mapZoom = payload
    },
    SET_PICTURE (state, payload) {
      payload.id = state.pictureIdCounter
      state.pictureIdCounter += 1
      Vue.set(state.pictures, payload.id, payload)
      state.pictureIds.push(payload.id)
    },
    SET_LOCATION_ICON (state, payload) {
      state.locations[payload.locationId].icon = L.icon({
        iconUrl: payload.icon,
        iconSize: [64, 64],
        iconAnchor: [32, 32],
        className: 'icon'
      })
    },
    SET_PICTURE_IMAGE (state, payload) {
      Vue.set(state.pictures[payload.pictureId], 'image', payload.image)
    },
    SET_PICTURE_ICON_LOADING (state, payload) {
      state.pictures[payload.pictureId].iconLoading = payload.set
    },
    SET_PICTURES (state, payload) {
      const pictures = {}
      const pictureIds = []
      let pictureIdCounter = state.pictureIdCounter
      for (const picture of payload) {
        picture.id = pictureIdCounter
        pictureIdCounter += 1
        pictures[picture.id] = picture
        pictureIds.push(picture.id)
      }

      state.pictureIdCounter = pictureIdCounter

      state.pictures = {
        ...state.pictures,
        ...pictures
      }

      state.pictureIds = [...state.pictureIds, ...pictureIds]
    },
    INCREASE_PROGRESS (state) {
      state.progress += 1
    },
    RESET_PROGRESS (state, items) {
      state.progress = 0
      state.numberOfItems = items
    },
    CREATE_LOCATION (state, options) {
      const newId = state.locationIdCounter
      state.locationIdCounter += 1
      Vue.set(state.locations, newId, {
        id: newId,
        latLng: options.latLng,
        pictures: [],
        populating: false
      })
      state.locationIds.push(newId)
    },
    ADD_PICTURE_TO_LOCATION (state, payload) {
      state.locations[payload.locationId].pictures.push(payload.picture.id)
    },
    UPDATE_LOCATION (state, payload) {
      Vue.set(state.locations[payload.locationId], payload.key, payload.data)
    },
    SET_UI_MAP (state, payload) {
      state.showUIMap = payload
    }
  },
  actions: {
    async pictureLocationAnalysis (context, picture) {
      if (context.state.locationIds.length > 0) {
        const locations = context.state.locationIds.filter(id => {
          return context.state.locations[id].latLng.distanceTo(picture.latLng) < 100
        })

        if (locations.length > 0) {
          locations.sort((a, b) => {
            return (context.state.locations[a].latLng.distanceTo(picture.latLng) < context.state.locations[b].latLng.distanceTo(picture.latLng) ? -1 : 1)
          })
          context.commit('ADD_PICTURE_TO_LOCATION', {
            picture,
            locationId: locations[0]
          })

          return
        }
      }

      // else
      context.commit('CREATE_LOCATION', {
        latLng: picture.latLng
      })
      const newLocId = context.state.locationIds[context.state.locationIds.length - 1]
      const newLoc = context.state.locations[newLocId]

      context.commit('ADD_PICTURE_TO_LOCATION', {
        picture,
        locationId: context.state.locationIds[context.state.locationIds.length - 1]
      })

      context.dispatch('populateLocationIcon', newLoc)
      context.dispatch('reverseCheckLocation', newLocId)
      context.commit('SET_MAP_CENTER', picture.latLng)
    },
    async reverseCheckLocation (context, locationId) {
      const location = context.state.locations[locationId]

      fetch(`https://nominatim.openstreetmap.org/reverse?lat=${location.latLng.lat}&lon=${location.latLng.lng}&format=json`)
        .then(response => response.json())
        .then(json => {
          context.commit('UPDATE_LOCATION', {
            locationId: locationId,
            key: 'address',
            data: json
          })
        })
    },
    async loadPictures (context, event) {
      const input = event.target

      if (input.files && input.files[0] && input.files.length > 0) {
        // const result = []
        context.commit('RESET_PROGRESS', input.files.length)
        for (const file of input.files) {
          if (file.type === 'image/jpeg') {
            const picture = await getFile(file)
            // result.push(picture)
            context.commit('SET_PICTURE', picture)
            if (picture.latLng) {
              context.dispatch('pictureLocationAnalysis', picture)
            }
          }
          context.commit('INCREASE_PROGRESS')
        }
        // context.commit('SET_PICTURES', result)
      }
    },
    async populateLocationIcon (context, location) {
      if (location.populatingIcon || location.pictures.length < 1) {
        return
      }

      if (location.icon) {
        return
      }

      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populatingIcon',
        data: true
      })

      const picture = context.state.pictures[location.pictures[0]]

      await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          resizedataURL(e.target.result, 64, 64)
            .then((resizedPicture) => {
              context.commit('SET_LOCATION_ICON', {
                locationId: location.id,
                icon: resizedPicture
              })
              resolve()
            })
            .catch((err) => {
              reject(err)
            })
        }

        reader.readAsDataURL(picture.file)
      })

      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populatingIcon',
        data: false
      })
    },
    async populateLocationPictures (context, locationId) {
      const location = context.state.locations[locationId]

      if (!location || location.populatingPictures || location.pictures.length < 1) {
        return
      }

      const picturesToPopulate = []
      for (const pictureId of location.pictures) {
        const picture = context.state.pictures[pictureId]
        if (!picture || picture.image) {
          continue
        }
        picturesToPopulate.push(picture)
      }

      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populatingPictures',
        data: picturesToPopulate.length
      })

      let progress = 0
      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populatingPicturesProgress',
        data: progress
      })

      for (const picture of picturesToPopulate) {
        // Cancel if overriden
        if (!location.populatingPictures) {
          return
        }

        await new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.onload = (e) => {
            context.commit('SET_PICTURE_IMAGE', {
              pictureId: picture.id,
              image: e.target.result
            })
            context.commit('UPDATE_LOCATION', {
              locationId: location.id,
              key: 'populatingPicturesProgress',
              data: ++progress
            })
            resolve()
          }

          reader.readAsDataURL(picture.file)
        })

        await new Promise(resolve => setTimeout(resolve, 100))
      }

      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populatingPictures',
        data: false
      })
    },
    async cancelPopulateLocationPictures (context, location) {
      if (location.populatingPictures) {
        context.commit('UPDATE_LOCATION', {
          locationId: location.id,
          key: 'populatingPictures',
          data: false
        })
      }
    },
    async populatePictureIcon (context, picture) {
      if (picture.icon) {
        return
      }
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
          context.commit('SET_PICTURE_ICON', {
            pictureId: picture.id,
            icon: e.target.result
          })
          resolve()
          /* resizedataURL(e.target.result, 128, 128)
            .then((resized) => {
            }) */
        }
        reader.readAsDataURL(picture.file)
      })
    },
    async populatePictureIcons (context, location) {
      if (location.populating) {
        return
      }

      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populating',
        data: true
      })

      const pictures = location.pictures
      for (const picture of pictures) {
        if (!picture.icon) {
          await context.dispatch('populatePictureIcon', picture)
          await new Promise(resolve => setTimeout(resolve, 100))
        }
      }
      context.commit('UPDATE_LOCATION', {
        locationId: location.id,
        key: 'populating',
        data: false
      })
    },
    async setMapCenter (context, payload) {
      // https://github.com/vue-leaflet/Vue2Leaflet/issues/170
      if (payload.zoom) {
        context.commit('SET_MAP_ZOOM', payload.zoom)
        if (payload.latLng) {
          setTimeout(() => {
            context.commit('SET_MAP_CENTER', payload.latLng)
          }, 500)
        }
      } else if (payload.latLng) {
        context.commit('SET_MAP_CENTER', payload.latLng)
      }
    },
    async setUIMap (context, payload) {
      context.commit('SET_UI_MAP', payload)
    }
  },
  modules: {
  }
})
